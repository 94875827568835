import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import { EDropdown } from './index';

import {
  FaCheckCircle,
  FaQuestionCircle,
  FaSearch,
  FaFacebook,
  FaYoutube,
  FaBug,
  FaPlusSquare,
  FaEnvelope,
} from 'react-icons/fa';

interface ICreateProps {
  show: boolean;
  transparentNav?: boolean;
  toggleDropDown: (type: EDropdown) => void;
  isMobile?: boolean;
  setActiveDropDown: (state: any) => void;
}

const Create: React.FC<ICreateProps> = ({
  show,
  transparentNav,
  toggleDropDown,
  isMobile,
  setActiveDropDown,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setActiveDropDown({
      create: false,
      help: true,
    });
  };

  const handleClose = () => {
    toggleDropDown(EDropdown.help);
    console.log('closed');
  };

  return (
    <div
      ref={divRef}
      style={{
        position: 'relative',
        color: 'black',
        margin: '0 0.5rem',
        display: 'inline-block',
        // display: 'flex',
        // alignItems: 'center',
        textAlign: 'left',
      }}>
      <button
        onClick={handleClick}
        onMouseEnter={handleClick}
        onMouseLeave={() => {
          setActiveDropDown({
            create: false,
            help: false,
          });
        }}
        style={{
          border: '1px solid',
          borderColor: transparentNav ? 'white' : '#7449fb',
          display: 'flex',
          padding: '0.5rem 0.5rem',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          borderRadius: '0.75rem',
          backgroundColor: 'transparent',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        }}>
        <span
          style={{
            fontWeight: '500',
            color: transparentNav ? 'white' : 'black',
          }}>
          Help
        </span>
        <FaQuestionCircle
          style={{
            marginLeft: '0.5rem',
            fontSize: '16px',
            color: transparentNav ? 'white' : 'black',
          }}
        />
      </button>

      <div
        onMouseEnter={() => {
          setActiveDropDown({
            create: false,
            help: true,
          });
        }}
        style={{
          position: 'absolute',
          top: '40px',
          height: '1.25rem',
          width: '100%',
        }}></div>

      {show && (
        <div
          onMouseLeave={() => {
            setActiveDropDown({
              create: false,
              help: false,
            });
          }}
          style={{
            border: '1px solid #ccc',
            transform: isMobile ? 'translateX(-50%)' : 'translateX(-70%)',
            position: 'absolute',
            left: '0',
            marginTop: '0.5rem',
            maxHeight: '80vh',
            overflow: 'auto',
            width: '300px',
            borderRadius: '0.5rem',
            boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
            backgroundColor: 'white',
          }}>
          <h4
            style={{
              fontFamily: 'sans-serif',
              padding: '0.75rem',
              paddingBottom: '0.5rem',
              fontWeight: '500',
              fontSize: '16px',
            }}>
            Help
          </h4>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '0.25rem',
            }}>
            <Link
              to="https://selfpublishingtitans.com/support"
              target="__blank"
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem',
                borderRadius: '0.375rem',
                fontFamily: 'sans-serif',
                fontWeight: '500',
                fontSize: '14.6px',
                textDecoration: 'none',
                color: 'inherit',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s, color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#c3abff';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.color = 'inherit';
              }}>
              <FaPlusSquare style={{ marginRight: '0.75rem' }} />
              Support
            </Link>
            <Link
              to="https://selfpublishingtitans.com/support"
              target="__blank"
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem',
                borderRadius: '0.375rem',
                fontFamily: 'sans-serif',
                fontWeight: '500',
                fontSize: '14.6px',
                textDecoration: 'none',
                color: 'inherit',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s, color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#c3abff';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.color = 'inherit';
              }}>
              <FaEnvelope style={{ marginRight: '0.75rem' }} />
              Feature Request
            </Link>
            <Link
              target="__blank"
              to="https://selfpublishingtitans.com/support"
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem',
                borderRadius: '0.375rem',
                fontFamily: 'sans-serif',
                fontWeight: '500',
                fontSize: '14.6px',
                textDecoration: 'none',
                color: 'inherit',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s, color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#c3abff';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.color = 'inherit';
              }}>
              <FaBug style={{ marginRight: '0.75rem' }} />
              Bug Report
            </Link>

            <Link
              to="https://www.facebook.com/groups/selfpublishingtitansofficial"
              target="__blank"
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem',
                borderRadius: '0.375rem',
                fontFamily: 'sans-serif',
                fontWeight: '500',
                fontSize: '14.6px',
                textDecoration: 'none',
                color: 'inherit',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s, color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#c3abff';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.color = 'inherit';
              }}>
              <FaFacebook style={{ marginRight: '0.75rem' }} />
              Community
            </Link>
            <button
              onClick={(e) => {
                e.stopPropagation();
                window.open(
                  'https://www.youtube.com/@SelfPublishingTitans',
                  '__blank',
                );
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem',
                borderRadius: '0.375rem',
                fontFamily: 'sans-serif',
                fontWeight: '500',
                fontSize: '14.6px',
                textDecoration: 'none',
                color: 'inherit',
                border: 'none',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s, color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#c3abff';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
                e.currentTarget.style.color = 'inherit';
              }}>
              <FaYoutube style={{ marginRight: '0.75rem' }} />
              Tutorials
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create;
