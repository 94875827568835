/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { FC, memo, useEffect, useState } from 'react';
import { Nav, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import type * as Type from '@/common/interface';
import { Avatar, Icon } from '@/components';
import { floppyNavigation } from '@/utils';
import { userCenterStore } from '@/stores';
import { getUserInfo } from '@/utils/next-auth';
import { CiUser } from 'react-icons/ci';
import { MdOutlineAccountCircle } from 'react-icons/md';
import { MdOutlineBook } from 'react-icons/md';
import { FaBookmark, FaRegUser } from 'react-icons/fa';
import { FaGear } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { FaCrown } from 'react-icons/fa';

interface Props {
  redDot: Type.NotificationStatus | undefined;
  userInfo: Type.UserInfoRes;
  logOut: (e) => void;
}

interface User {
  name: string;
  email: string;
  image: string;
}

interface UserInfo {
  user: User;
  expires: string;
  name: string;
  email: string;
  picture: string;
  sub: string;
  verified: boolean;
  type: number;
  source: string;
  is_blocked: boolean;
  _id: string;
  __v: number;
  createdAt: string;
  updatedAt: string;
  google_id: string;
  is_admin: boolean;
  id: string;
  token: string;
  iat: number;
  exp: number;
  jti: string;
}

interface Feature {
  _id: string;
  feature_name: string;
  feature_id: string;
  published_at: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  created_by: string;
  updated_by: string;
  id: string;
}

interface PaymentData {
  payment_service: string;
  _id: string;
  account: string;
  feature_id: Feature;
  feature_shop: string;
  payment_intent: string;
  __v: number;
  createdAt: string;
  updatedAt: string;
  id: string;
}

const isOwned = (featuresOwned, id) => {
  return featuresOwned?.find((item) => item.feature_id._id === id);
};

const Index: FC<Props> = ({ redDot, userInfo, logOut }) => {
  const { t } = useTranslation();
  const [info, setInfo] = useState<UserInfo | null>(null);

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { agent: ucAgent } = userCenterStore();
  const handleLinkClick = (evt) => {
    if (floppyNavigation.shouldProcessLinkClick(evt)) {
      evt.preventDefault();
      const href = evt.currentTarget.getAttribute('href');
      floppyNavigation.navigate(href, {
        handler: navigate,
      });
    }
  };

  async function fetchUserInfo() {
    const _info = await getUserInfo();
    if (_info) {
      setInfo(_info as UserInfo);
    }
  }

  useEffect(() => {
    fetchUserInfo();
  }, []);

  function NeedToUpgrade(features): {
    upgradeTo: string;
    currentTier: string;
    show: boolean;
  } {
    try {
      if (!features) {
        console.error('Invalid info structure');
        return {
          upgradeTo: '0',
          currentTier: '',
          show: false,
        };
      }

      if (isOwned(features, '64562adce2210da37f2bdb2a')) {
        return {
          upgradeTo: 'Pro Max',
          currentTier: 'Pro',
          show: true,
        };
      }
      if (isOwned(features, '6516aecf8a69c334783b3c27')) {
        return {
          upgradeTo: 'Supreme',
          currentTier: 'Pro Max',
          show: true,
        };
      }

      if (isOwned(features, '655ee43f1727b2465e13079b')) {
        return {
          upgradeTo: 'Supreme',
          currentTier: 'Supreme',
          show: false,
        };
      }

      return {
        upgradeTo: 'Pro',
        currentTier: 'Free',
        show: true,
      };
    } catch (error) {
      console.error('Error in NeedToUpgrade function:', error);
      return {
        upgradeTo: '',
        currentTier: '',
        show: false,
      };
    }
  }

  return (
    <>
      {/* <Nav className="flex-row">
        <NavLink
          to="/users/notifications/inbox"
          title={t('inbox', { keyPrefix: 'notifications' })}
          className="icon-link nav-link d-flex align-items-center justify-content-center p-0 me-3 position-relative">
          <Icon name="bell-fill" className="fs-4" />
          {(redDot?.inbox || 0) > 0 && (
            <div className="unread-dot bg-danger">
              <span className="visually-hidden">
                {t('new_alerts', { keyPrefix: 'notifications' })}
              </span>
            </div>
          )}
        </NavLink>

        <NavLink
          to="/users/notifications/achievement"
          title={t('achievement', { keyPrefix: 'notifications' })}
          className="icon-link nav-link d-flex align-items-center justify-content-center p-0 me-3 position-relative">
          <Icon name="trophy-fill" className="fs-4" />
          {(redDot?.achievement || 0) > 0 && (
            <div className="unread-dot bg-danger">
              <span className="visually-hidden">
                {t('new_alerts', { keyPrefix: 'notifications' })}
              </span>
            </div>
          )}
        </NavLink>
      </Nav> */}

      <Dropdown
        align="end"
        style={{
          marginLeft: '8px',
          display: 'flex',
          alignItems: 'center',
        }}>
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          as="a"
          role="button"
          className="no-toggle pointer">
          {info?.user?.name ? (
            <Avatar
              size="36px"
              avatar={info?.user?.image || ''}
              alt={userInfo?.display_name}
              searchStr="s=96"
            />
          ) : (
            // @ts-ignore
            <Avatar alt="" size="36px" searchStr="s=96" />
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            minWidth: '280px',
            padding: '10px',
            paddingTop: '10px',
          }}
          className="">
          <div
            style={{
              border: '1px solid #ccc',
              padding: '8px',
              borderRadius: '8px',
              marginBottom: '10px',
            }}
            className="">
            <div className="d-flex align-items-center">
              <Avatar
                size="48px"
                avatar={info?.user?.image || ''}
                alt={userInfo?.display_name}
                searchStr="s=96"
              />
              <div className="ms-2">
                <div className="fw-bold">{info?.user?.name}</div>
                <div className="text-muted">{info?.user?.email}</div>
              </div>
            </div>
          </div>

          <NavLink
            to={`/users/${userInfo.username}`}
            className={() => 'dropdown-item d-flex align-items-center'}
            onClick={handleLinkClick}>
            <>
              <FaRegUser className="me-2" />
              {t('header.nav.profile')}
            </>
          </NavLink>
          <NavLink
            target="_blank"
            to={'https://selfpublishingtitans.com/book-planner'}
            className={() => 'dropdown-item d-flex align-items-center'}
            onClick={handleLinkClick}>
            <>
              <MdOutlineBook className="me-2" />
              Notest & Planning
            </>
          </NavLink>
          <NavLink
            to={`/users/${userInfo.username}/bookmarks`}
            className={() => 'dropdown-item d-flex align-items-center'}
            onClick={handleLinkClick}>
            <>
              <FaBookmark className="me-2" />
              {t('header.nav.bookmark')}
            </>
          </NavLink>
          <NavLink
            to="/users/settings/profile"
            className={() => 'dropdown-item d-flex align-items-center'}
            onClick={handleLinkClick}>
            <>
              <FaGear className="me-2" />
              {t('header.nav.setting')}
            </>
          </NavLink>
          <Dropdown.Divider />
          {/* <NavLink
            to="/users/logout"
            className={() => 'dropdown-item'}
            onClick={(e) => logOut(e)}>
            {t('header.nav.logout')}
          </NavLink> */}
        </Dropdown.Menu>
      </Dropdown>
      {/* Dropdown for user center agent info */}
      {ucAgent?.enabled &&
      (ucAgent?.agent_info?.url ||
        ucAgent?.agent_info?.control_center?.length) ? (
        <Dropdown align="end">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-uca"
            as="span"
            className="no-toggle">
            <Nav>
              <Icon
                name="grid-3x3-gap-fill"
                className="nav-link pointer p-0 fs-4 ms-3"
              />
            </Nav>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {ucAgent.agent_info.url ? (
              <Dropdown.Item href={ucAgent.agent_info.url}>
                {ucAgent.agent_info.name}
              </Dropdown.Item>
            ) : null}
            {ucAgent.agent_info.url &&
            ucAgent.agent_info.control_center?.length ? (
              <Dropdown.Divider />
            ) : null}
            {ucAgent.agent_info.control_center?.map((ctrl) => {
              return (
                <Dropdown.Item key={ctrl.name} href={ctrl.url}>
                  {ctrl.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
    </>
  );
};

export default memo(Index);

interface IUpgradeProps {
  info: UserInfo;
  isUpgrade: {
    upgradeTo: string;
    currentTier: string;
    show: boolean;
  };
}
function Upgrade({ info, isUpgrade }: IUpgradeProps) {
  if (!isUpgrade.show) {
    return null;
  }

  return (
    <Link
      to={'https://selfpublishingtitans.com/pricing'}
      style={{
        borderRadius: '10px',
      }}
      className="p-2 py-3 m-1 text-[15px] text-black flex justify-center items-center bg-[#7449fb] text-white rounded-xl font-sans border border-white font-bold shadow-sm">
      Upgrade <FaCrown className="ml-2" />
    </Link>
  );
}
