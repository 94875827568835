/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { FC, memo, useState, useEffect } from 'react';
import {
  Navbar,
  Container,
  Nav,
  // Form,
  // FormControl,
  Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  useSearchParams,
  Link,
  // useNavigate,
  useLocation,
  useMatch,
} from 'react-router-dom';

import classnames from 'classnames';

import { userCenter, floppyNavigation } from '@/utils';
import {
  loggedUserInfoStore,
  siteInfoStore,
  brandingStore,
  loginSettingStore,
  themeSettingStore,
  sideNavStore,
} from '@/stores';
import { logout, useQueryNotificationStatus } from '@/services';
import SearchComp from '@/components/Header/components/search';

import SolutionsDropDown from './Solutions';
import ResourcesDropDown from './Resources';
import NavItems from './components/NavItems';
import HelpComp from './Help';
// getTokenFromNextAuth;
import { getTokenFromNextAuth } from '@/utils/next-auth';
import Create from './Create';
import ChromeWebstore from './ChromeStore';
import { FaBars } from 'react-icons/fa';
import { getUserInfo } from '@/utils/next-auth';
import { FaTrophy, FaFire } from 'react-icons/fa6';
import Notifications from './Notifications';

import './index.scss';

interface User {
  name: string;
  email: string;
  image: string;
}

interface UserInfo {
  user: User;
  expires: string;
  name: string;
  email: string;
  picture: string;
  sub: string;
  verified: boolean;
  type: number;
  source: string;
  is_blocked: boolean;
  _id: string;
  __v: number;
  createdAt: string;
  updatedAt: string;
  google_id: string;
  is_admin: boolean;
  id: string;
  token: string;
  iat: number;
  exp: number;
  jti: string;
}

const mainSite = 'https://selfpublishingtitans.com';

export enum EDropdown {
  notification = 'notification',
  create = 'create',
  help = 'help',
  chrome = 'chrome',
}

const Header: FC = () => {
  // const navigate = useNavigate();
  const location = useLocation();
  const [urlSearch] = useSearchParams();
  const q = urlSearch.get('q');
  const { user, clear: clearUserStore } = loggedUserInfoStore();

  const { t } = useTranslation();
  const [searchStr, setSearch] = useState('');
  const siteInfo = siteInfoStore((state) => state.siteInfo);
  const brandingInfo = brandingStore((state) => state.branding);
  const loginSetting = loginSettingStore((state) => state.login);
  const { updateReview, updateVisible } = sideNavStore();
  const { data: redDot } = useQueryNotificationStatus();

  /**
   * Automatically append `tag` information when creating a question
   */
  const tagMatch = useMatch('/tags/:slugName');
  let askUrl = '/questions/ask';
  if (tagMatch && tagMatch.params.slugName) {
    askUrl = `${askUrl}?tags=${encodeURIComponent(tagMatch.params.slugName)}`;
  }

  const [userRank, setUserRank] = useState(0);
  const [totalXp, setTotalXp] = useState(0);
  const [streak, setStreak] = useState(0);

  const base = 'https://stingray-app-tx5jj.ondigitalocean.app';

  async function get_user_rank(duration, user_id) {
    try {
      const url = `${base}/api/usage/leaderboard/${duration}/${user_id}`;
      const response = await fetch(url);
      const data = await response.json();
      return { data };
    } catch (error) {}
  }

  async function get_total_points(user_id) {
    try {
      const url = base + `/api/usage/xp/total/${user_id}`;
      const response = await fetch(url);
      const data = await response.json();
      return { data };
    } catch (error) {}
  }

  async function get_current_streak(user_id) {
    try {
      const url = `${base}/api/usage/streak/${user_id}`;
      const response = await fetch(url);
      const data = await response.json();
      return { data };
    } catch (error) {}
  }

  useEffect(() => {
    updateReview({
      can_revision: Boolean(redDot?.can_revision),
      revision: Number(redDot?.revision),
    });
  }, [redDot]);

  const handleInput = (val) => {
    setSearch(val);
  };

  const fetchUserStat = async (user_id: string) => {
    console.log('user id is', user_id);

    if (!user_id) {
      return;
    }

    try {
      const rankData = await get_user_rank('this_month' as any, user_id);
      console.log('rank data is', rankData);
      // @ts-ignore
      setUserRank(rankData?.data?.rank || 0);

      const xpData = await get_total_points(user_id);
      // @ts-ignore
      setTotalXp(xpData?.data?.total_xp || 0);

      const streadData = await get_current_streak(user_id);
      // @ts-ignore
      setStreak(streadData?.data?.current_streak || 0);
    } catch (error) {}
  };

  const [info, setInfo] = useState<UserInfo | null>(null);

  // useEffect(() => {
  //   fetchUserStat();
  // }, [user_id]);

  async function fetchUserInfo() {
    const _info = await getUserInfo();
    if (_info) {
      setInfo(_info as UserInfo);
      fetchUserStat(_info._id);
    }
  }

  useEffect(() => {
    fetchUserInfo();
  }, []);

  // const handleSearch = (evt) => {
  //   evt.preventDefault();
  //   if (!searchStr) {
  //     return;
  //   }
  //   const searchUrl = `/search?q=${encodeURIComponent(searchStr)}`;
  //   navigate(searchUrl);
  // };

  const handleLogout = async (evt) => {
    evt.preventDefault();
    // await logout();
    // clearUserStore();
    window.location.replace(window.location.href);
  };

  useEffect(() => {
    if (q && location.pathname === '/search') {
      handleInput(q);
    }
  }, [q]);

  useEffect(() => {
    const collapse = document.querySelector('#navBarContent');
    if (collapse && collapse.classList.contains('show')) {
      const toggle = document.querySelector('#navBarToggle') as HTMLElement;
      if (toggle) {
        toggle?.click();
      }
    }

    // clear search input when navigate to other page
    if (location.pathname !== '/search' && searchStr) {
      setSearch('');
    }
  }, [location.pathname]);

  let navbarStyle = 'theme-colored';
  const { theme, theme_config } = themeSettingStore((_) => _);
  if (theme_config?.[theme]?.navbar_style) {
    navbarStyle = `theme-${theme_config[theme].navbar_style}`;
  }

  enum DropDownTypes {
    services = 'services',
    resources = 'resources',
  }

  const [selectedDropDown, setSelectedDropDown] =
    useState<DropDownTypes | null>(null);

  const toggleDropDown = (type: DropDownTypes) => {
    if (selectedDropDown === type) {
      setSelectedDropDown(null);
    } else {
      setSelectedDropDown(type);
    }
  };

  const [activeDropDown, setActiveDropDown] = useState({
    notification: false,
    create: false,
    help: false,
    chrome: false,
  });

  const toggleDropDown2 = (type: EDropdown) => {
    setActiveDropDown((prevState) => {
      const newState = Object.keys(prevState).reduce(
        (acc, key) => {
          acc[key as EDropdown] = false;
          return acc;
        },
        {} as { [key in EDropdown]: boolean },
      );

      return {
        ...newState,
        [type]: !prevState[type],
      };
    });
  };

  return (
    <Navbar
      variant={navbarStyle === 'theme-colored' ? 'dark' : ''}
      expand="lg"
      style={{
        background: 'white',
        color: 'black',
        width: '100%',
        // maxWidth: '95vw', // Added maxWidth here
      }}
      className={classnames('sticky-top', navbarStyle)}
      id="header">
      <div
        style={{
          width: '100%',
          // padding: '0 60px',
        }}
        className="d-flex justify-content-between px-3 px-lg-5 align-items-center nav-grow flex-nowrap  w-full ">
        <div className="d-flex justify-content-between align-items-center nav-grow flex-nowrap">
          <Navbar.Toggle
            aria-controls="navBarContent"
            className="answer-navBar me-2"
            id="navBarToggle"
            onClick={updateVisible}>
            <FaBars color="black" />
          </Navbar.Toggle>
          <Navbar.Brand to="/" as={Link} className="lh-1 me-0 me-sm-5 p-0">
            {brandingInfo.logo ? (
              <>
                {/* <img
                  className="d-none d-lg-block logo me-0"
                  src={brandingInfo.logo}
                  alt={siteInfo.name}
                /> */}
                {/* <img
                  className="lg-none logo me-0"
                  src={brandingInfo.mobile_logo || brandingInfo.logo}
                  alt={siteInfo.name}
                /> */}
              </>
            ) : (
              <span
                style={{
                  color: '#000',
                }}
                className="">
                {siteInfo.name}
              </span>
            )}
          </Navbar.Brand>

          <div
            style={{
              marginLeft: '4px',
              marginRight: '4px',
            }}
            className="d-none d-lg-block"
            onMouseLeave={() => setSelectedDropDown(null)}
            onMouseEnter={() => toggleDropDown(DropDownTypes.services)}>
            <SolutionsDropDown
              show={selectedDropDown === DropDownTypes.services}
            />
          </div>

          <div
            style={{
              marginLeft: '4px',
              marginRight: '4px',
            }}
            className="d-none d-lg-block"
            onMouseLeave={() => setSelectedDropDown(null)}
            onMouseEnter={() => toggleDropDown(DropDownTypes.resources)}>
            <ResourcesDropDown
              show={selectedDropDown === DropDownTypes.resources}
              // show={true}
            />
          </div>
          <div
            style={{
              marginLeft: '4px',
              marginRight: '4px',
            }}
            className="d-none d-lg-block">
            <Link
              target="_blank"
              to={'https://selfpublishingtitans.com/titans-pro?tab=month'}
              className={classnames('text-capitalize text-nowrap btn', {
                'btn-light': navbarStyle !== 'theme-light',
                'btn-primary': navbarStyle === 'theme-light',
              })}>
              Pricing
            </Link>
          </div>
          <div
            style={{
              marginLeft: '4px',
              marginRight: '4px',
            }}
            className="d-none d-lg-block">
            <Link
              to={'https://selfpublishingtitans.com/testimonials'}
              target="_blank"
              className={classnames('text-capitalize text-nowrap btn', {
                'btn-light': navbarStyle !== 'theme-light',
                'btn-primary': navbarStyle === 'theme-light',
              })}>
              Testimonials
            </Link>
          </div>

          <div
            style={{
              marginLeft: '4px',
              marginRight: '4px',
            }}
            className="d-block d-lg-none">
            <Link
              to={askUrl}
              className={classnames('text-capitalize text-nowrap btn', {
                'btn-light': navbarStyle !== 'theme-light',
                'btn-primary': navbarStyle === 'theme-light',
              })}>
              {t('btns.add_question')}
            </Link>
          </div>

          {/* mobile nav */}
          <div className="d-flex lg-none align-items-center flex-lg-nowrap">
            {user?.username ? (
              <NavItems
                redDot={redDot}
                userInfo={user}
                logOut={(e) => handleLogout(e)}
              />
            ) : (
              <>
                <Link
                  className={classnames(
                    'btn',
                    navbarStyle === 'theme-colored'
                      ? 'btn-light'
                      : 'btn-primary',
                  )}
                  onClick={() => floppyNavigation.storageLoginRedirect()}
                  to={userCenter.getLoginUrl()}>
                  {t('btns.login')}
                </Link>
                {loginSetting.allow_new_registrations && (
                  <Link
                    className={classnames(
                      'btn',
                      navbarStyle === 'theme-colored'
                        ? 'btn-light'
                        : 'btn-primary',
                    )}
                    to={userCenter.getSignUpUrl()}>
                    {t('btns.signup')}
                  </Link>
                )}
              </>
            )}
          </div>
        </div>

        <Navbar.Collapse
          id="navBarContent"
          className="me-auto d-none d-lg-block ">
          <hr className="hr lg-none mb-3" style={{ marginTop: '12px' }} />
          {/* <Col lg={4} className="ps-0"> */}
          {/* <Form
              action="/search"
              className="w-100 maxw-400 position-relative"
              onSubmit={handleSearch}>
              <div className="search-wrap" onClick={handleSearch}>
                <Icon name="search" className="search-icon" />
              </div>
              <FormControl
                type="search"
                placeholder={t('header.search.placeholder')}
                className="placeholder-search"
                value={searchStr}
                name="q"
                onChange={(e) => handleInput(e.target.value)}
              />
            </Form> */}
          {/* </Col> */}

          {/* <Nav.Item className="lg-none mt-3 pb-1">
            <Link
              to={askUrl}
              className="text-capitalize text-nowrap btn btn-light">
              {t('btns.add_question')}
            </Link>
          </Nav.Item> */}
          {/* pc nav */}
          <Col
            lg={12}
            className="d-none d-lg-flex justify-content-start align-items-center justify-content-sm-end">
            {user?.username ? (
              <Nav className="d-flex align-items-center flex-lg-nowrap">
                <Nav.Item
                  className="me-3"
                  style={{
                    border: '1px solid #7449fb',
                    borderRadius: '0.5rem',

                    borderColor: '#7449fb',
                  }}>
                  <Link
                    to={askUrl}
                    className={classnames('text-capitalize text-nowrap btn', {
                      'btn-light': navbarStyle !== 'theme-light',
                      'btn-primary': navbarStyle === 'theme-light',
                    })}>
                    {t('btns.add_question')}
                  </Link>
                </Nav.Item>
              </Nav>
            ) : (
              <></>
            )}

            <SearchComp />
            <ChromeWebstore
              toggleDropDown={toggleDropDown2}
              show={activeDropDown.chrome || false}
              transparentNav={false}
              setActiveDropDown={setActiveDropDown}
            />
            <Create
              toggleDropDown={toggleDropDown2}
              show={activeDropDown.create || false}
              transparentNav={false}
              setActiveDropDown={setActiveDropDown}
            />
            <HelpComp
              toggleDropDown={toggleDropDown2}
              show={activeDropDown.help || false}
              transparentNav={false}
              setActiveDropDown={setActiveDropDown}
            />
            {user?.username && info && (
              // {true && (
              <Notifications
                toggleDropDown={toggleDropDown2}
                show={activeDropDown.notification || false}
                transparentNav={false}
                setActiveDropDown={setActiveDropDown}
                // @ts-ignore
                info={info}
              />
            )}

            {user?.username ? (
              // <Nav className="d-flex align-items-center flex-lg-nowrap">
              <div
                style={{
                  padding: '6px',
                  border: '1px solid #ccc',
                  display: 'flex',
                  borderRadius: '20px',
                }}
                className="">
                {totalXp && (
                  <div
                    onClick={() => window.open(mainSite + '/profile', '_blank')}
                    style={{
                      display: 'flex',
                      gap: '3px',
                      alignItems: 'center',
                      marginRight: '6px',
                      cursor: 'pointer',
                    }}
                    className="">
                    <FaTrophy
                      style={{
                        color: '#7449fb',
                      }}
                      className=""
                    />
                    <span className="ms-1">{totalXp}</span>
                  </div>
                )}
                {streak && (
                  <div
                    onClick={() => window.open(mainSite + '/profile', '_blank')}
                    style={{
                      display: 'flex',
                      gap: '3px',
                      alignItems: 'center',
                      marginRight: '6px',
                      cursor: 'pointer',
                    }}
                    className="">
                    <FaFire
                      style={{
                        color: '#7449fb',
                      }}
                    />
                    <span className="ms-1">{streak}</span>
                  </div>
                )}
                <NavItems
                  redDot={redDot}
                  userInfo={user}
                  logOut={handleLogout}
                />
              </div>
            ) : (
              // </Nav>
              <>
                <Link
                  style={{
                    color: 'black',
                  }}
                  className={classnames('me-2 btn btn-link', {
                    '': navbarStyle === 'theme-colored',
                    'link-primary': navbarStyle !== 'theme-colored',
                  })}
                  onClick={() => floppyNavigation.storageLoginRedirect()}
                  to={mainSite + '/auth/login?next=' + window.location}>
                  {t('btns.login')}
                </Link>
                {loginSetting.allow_new_registrations && (
                  <Link
                    className={classnames(
                      'btn',
                      navbarStyle === 'theme-colored'
                        ? 'btn-light'
                        : 'btn-primary',
                    )}
                    to={mainSite + '/auth/register?next=' + window.location}>
                    {t('btns.signup')}
                  </Link>
                )}
              </>
            )}
          </Col>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default memo(Header);
