import React, { useRef, useState, useEffect } from 'react';
import {
  MdAdd,
  MdPhotoAlbum,
  MdImage,
  MdExtension,
  MdLibraryBooks,
  MdSearch,
  MdFilter7,
  MdYoutubeSearchedFor,
  MdBrush,
  MdTranslate,
  MdPriceChange,
  MdSubtitles,
  MdEditNote,
  MdGavel,
  MdAttachMoney,
  MdSpellcheck,
  MdVerified,
  MdQrCode2,
  MdCloud,
  MdAdsClick,
  MdBallot,
  MdSchool,
  MdCompare,
  MdMenuBook,
  MdOutlineCategory,
  MdCreate,
} from 'react-icons/md';

import { FaAmilia } from 'react-icons/fa6';

import { FaYoutube } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { EDropdown } from './index';
import './create.scss';

type TOption = {
  name: string;
  Icon: React.ElementType;
  tag?: string;
  link: string;
};

function EditNoteicon({ style }: { style?: React.CSSProperties }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-4"
      height="35px"
      viewBox="0 -960 960 960"
      width="35px"
      fill="#00000"
      style={style}>
      <path d="M480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v200h-80v-145l-240-90-240 90v189q0 121 68 220t172 132q26-8 49.5-20.5T576-214l56 56q-33 27-71.5 47T480-80Zm360 0q-17 0-28.5-11.5T800-120q0-17 11.5-28.5T840-160q17 0 28.5 11.5T880-120q0 17-11.5 28.5T840-80Zm-40-160v-240h80v240h-80ZM480-480Zm0 80q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400Zm0 80q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 22-5.5 42.5T618-398l119 118-57 57-120-119q-18 11-38.5 16.5T480-320Z" />
    </svg>
  );
}

function TextCompare({ style }: { style?: React.CSSProperties }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-4"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="#00000"
      style={style}>
      <path d="M400-40v-80H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h200v-80h80v880h-80ZM200-200h200v-80H280v-80h120v-80H280v-80h120v-80H280v-80h120v-80H200v560Zm360-560v-80h200q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H560v-80h200v-560H560Zm0 320v-80h120v80H560Zm0-160v-80h120v80H560ZM400-480Z" />
    </svg>
  );
}

function TwoPages() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="34px"
      className="mr-4"
      viewBox="0 -960 960 960"
      width="34px"
      fill="#00000">
      <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h280v-480H160v480Zm360 0h280v-480H520v480Zm-320-80h200v-80H200v80Zm0-120h200v-80H200v80Zm0-120h200v-80H200v80Zm360 240h200v-80H560v80Zm0-120h200v-80H560v80Zm0-120h200v-80H560v80ZM440-240v-480 480Z" />
    </svg>
  );
}

const CreateOptions: TOption[] = [
  {
    name: 'Book Cover',
    Icon: MdPhotoAlbum,
    link: 'https://selfpublishingtitans.com/book-cover-creator',
  },
  {
    name: 'A+ Content',
    Icon: MdImage,
    link: 'https://selfpublishingtitans.com/A+-content-template-generator',
  },
  {
    name: 'Write Book',
    Icon: MdMenuBook,
    link: 'https://author.selfpublishingtitans.com',
  },
  {
    name: 'Puzzle Pages',
    Icon: MdExtension,
    link: 'https://books.selfpublishingtitans.com/Puzzle-Maker-Software',
  },
  {
    name: 'Low Content Pages',
    Icon: MdLibraryBooks,
    link: 'https://books.selfpublishingtitans.com/FREE-No-Content-Book-Creator-Software/start-now?simple=true',
  },
  {
    name: 'Coloring Book',
    Icon: MdBrush,
    link: 'https://editor.selfpublishingtitans.com',
  },
  {
    name: 'Digital Titans Designer',
    Icon: FaAmilia,
    link: 'https://design.selfpublishingtitans.com/',
  },
];

const ResearchOptions: TOption[] = [
  {
    name: 'Titans Pro',
    tag: 'Search Suggestions',
    Icon: MdSearch,
    link: 'https://chromewebstore.google.com/detail/titans-pro-amazon-kdp-key/mmdamlknnafgffhlobhlmiljonijdnid',
  },
  {
    name: 'Deep View',
    tag: 'Niche Analysis',
    Icon: MdBallot,
    link: 'https://selfpublishingtitans.com/titans-deep-view',
  },
  {
    name: '7 Backend Keywords',
    tag: 'Book Listing SEO',
    Icon: MdFilter7,
    link: 'https://selfpublishingtitans.com/7-backend-keywords-tool-amazon-kdp',
  },
  {
    name: 'Retro View',
    tag: 'Competetior Analysis',
    Icon: MdYoutubeSearchedFor,
    link: 'https://selfpublishingtitans.com/titans-retro-vision',
  },
  {
    name: 'Quick View',
    tag: 'Quick Analysis',
    Icon: MdVerified,
    link: 'https://chromewebstore.google.com/detail/titans-quick-view-amazon/eefljgmhgaidffapnppcmmafobefjece',
  },
];

const GeneralTools: TOption[] = [
  {
    name: 'KDP BSR Sales Calculator',
    tag: "Estimate anyone's book sales",
    link: 'https://selfpublishingtitans.com/tools/KDP-BSR-Sales-Calculator',
    Icon: MdPriceChange,
  },
  {
    name: 'ACOS Royalty Calculator',
    tag: 'Calculate ad campaign profitability.',
    link: 'https://selfpublishingtitans.com/tools/KDP-Royalty-Calculator',
    Icon: MdAdsClick,
  },
  {
    name: 'KDP Category Finder',
    tag: 'Find the best categories for your book and improve your SEO.',
    link: 'https://selfpublishingtitans.com/tools/KDP-Kindle-Category-Finder',
    Icon: MdOutlineCategory,
  },
  {
    name: 'QR Code Generator',
    tag: 'Create QR codes for your books',
    link: 'https://selfpublishingtitans.com/tools/QR-Code-Generator-for-KDP-Books',
    Icon: MdQrCode2,
  },
  {
    name: 'Pen Name Generator',
    tag: 'Create pen names for your books',
    link: 'https://selfpublishingtitans.com/tools/Pen-Name-Generator',
    Icon: MdCreate,
  },
  {
    name: 'Word Cloud Generator',
    tag: 'View most used words and keywords',
    link: 'https://selfpublishingtitans.com/word-cloud',
    Icon: MdCloud,
  },
  {
    name: 'Keyword Tracker',
    tag: 'Track Keyword ranking for any product',
    link: 'https://selfpublishingtitans.com/titans-keyword-tracker',
    Icon: TextCompare,
  },
  {
    name: 'ASIN Tracker',
    tag: 'Monitor any products performance',
    link: 'https://selfpublishingtitans.com/titans-asin-tracker',
    Icon: MdCompare,
  },
  {
    name: 'KDP Cover Template Generator',
    tag: 'Create KDP Cover Templates',
    link: 'https://selfpublishingtitans.com/kdp-cover-template-generator',
    Icon: TwoPages,
  },
];

const LearningTools: TOption[] = [
  {
    name: 'Youtube Tutorials',
    Icon: FaYoutube,
    link: 'https://www.youtube.com/@SelfPublishingTitans',
  },
  {
    name: 'KDP Masterclass',
    Icon: MdSchool,
    link: 'https://www.selfpublishingtitans.com/masterclass',
  },
];

const BookListingTools: TOption[] = [
  {
    name: 'KDP Title Creator',
    Icon: MdSubtitles,
    link: 'https://selfpublishingtitans.com/kdp-title-creator',
  },
  {
    name: 'KDP Description Generator',
    Icon: MdEditNote,
    link: 'https://selfpublishingtitans.com/kdp-description-creator',
  },
  {
    name: 'KDP Trademark Violation Checker',
    Icon: EditNoteicon,
    link: 'https://selfpublishingtitans.com/kdp-trademark-checker',
  },
  {
    name: 'KDP Guidelines Checker',
    Icon: MdGavel,
    link: 'https://selfpublishingtitans.com/kdp-guidelines-checker',
  },
  {
    name: 'KDP Book Data Translator',
    Icon: MdTranslate,
    link: 'https://selfpublishingtitans.com/kdp-bookdata-translator',
  },
  {
    name: 'Book Price Suggestion Tool',
    Icon: MdAttachMoney,
    link: 'https://www.selfpublishingtitans.com/book-price',
  },
  {
    link: 'https://www.selfpublishingtitans.com/grammar-check',
    name: 'Grammar Checker',
    Icon: MdSpellcheck,
  },
];

interface ICreateProps {
  show: boolean;
  transparentNav?: boolean;
  toggleDropDown: (type: EDropdown) => void;
  isMobile?: boolean;
  setActiveDropDown: (state: any) => void;
}

const Create: React.FC<ICreateProps> = ({
  show,
  transparentNav,
  toggleDropDown,
  isMobile,
  setActiveDropDown,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setActiveDropDown({
      create: true,
      help: false,
    });
    // toggleDropDown(EDropdown.help);
    // console.log("handling click");
  };

  const handleClose = () => {
    // setIsOpen(false);
    // toggleDropDown(EDropdown.help);
    // console.log("closed");
    setActiveDropDown({
      create: false,
      help: false,
    });
  };

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (divRef.current && !divRef.current.contains(event.target as Node)) {
  //     setActiveDropDown({
  //       create: false,
  //       help: false,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (show) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [show]);

  return (
    <div
      ref={divRef}
      style={{
        position: 'relative',
        color: 'black',
        margin: '0 0.5rem',
        // marginTop: '0.1rem',
        display: 'inline-block',
        textAlign: 'left',
      }}>
      <button
        onClick={handleClick}
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
        style={{
          border: '1px solid',
          borderColor: transparentNav ? 'white' : '#7449fb',
          display: 'flex',
          padding: '0.5rem 1rem',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          borderRadius: '0.75rem',
          backgroundColor: 'transparent',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        }}>
        <span
          style={{
            fontWeight: 500,
            color: transparentNav ? 'white' : 'black',
          }}>
          Start
        </span>
        <MdAdd
          style={{
            marginLeft: '0.5rem',
            fontSize: '20px',
            color: transparentNav ? 'white' : 'black',
          }}
        />
      </button>
      <div
        onMouseEnter={() => {
          setActiveDropDown({
            create: true,
            help: false,
          });
        }}
        style={{
          position: 'absolute',
          top: '40px',
          height: '1.25rem',
          width: '100%',
        }}></div>
      {show && (
        <div
          style={{
            border: '1px solid #ccc',
            transform: isMobile ? 'translateX(-50%)' : 'translateX(-70%)',
            position: 'absolute',
            left: 0,
            marginTop: '0.5rem',
            maxHeight: '80vh',
            overflow: 'auto',
            width: '88vw',
            maxWidth: '650px',
            borderRadius: '0.75rem',
            boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
            backgroundColor: 'white',
          }}
          onMouseLeave={handleClose}>
          <div
            style={{
              padding: '0.25rem 0.75rem',
            }}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                width: '100%',
              }}>
              <div>
                <span
                  style={{
                    color: 'black',
                    marginBottom: '0.25rem',
                    marginTop: '0.25rem',
                    fontWeight: 'bold',
                  }}>
                  Create
                </span>
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                  }}>
                  {CreateOptions.map((option, index) => (
                    <Link
                      key={index}
                      to={option.link}
                      target="__blank"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '0.5rem',
                        padding: '0.375rem 0.25rem',
                        fontSize: '0.875rem',
                        color: 'gray',
                        textDecoration: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#c3abff';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.color = 'inherit';
                      }}
                      onClick={(e) => e.stopPropagation()}>
                      <option.Icon
                        style={{
                          marginRight: '1rem',
                          fontSize: '20px',
                          color: 'black',
                        }}
                      />
                      <span style={{ fontWeight: '500', color: 'black' }}>
                        {option.name}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
              <span
                style={{
                  color: 'black',
                  marginBottom: '0.25rem',
                  marginTop: '1rem',
                  fontWeight: 'bold',
                }}>
                Research
              </span>
              <div
                style={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                }}>
                {ResearchOptions.map((option, index) => (
                  <Link
                    key={index}
                    to={option.link}
                    target="__blank"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '0.5rem',
                      padding: '0.375rem 0.25rem',
                      fontSize: '0.875rem',
                      color: 'gray',
                      textDecoration: 'none',
                      backgroundColor: 'transparent',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = '#c3abff';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                      e.currentTarget.style.color = 'inherit';
                    }}
                    role="menuitem">
                    <option.Icon
                      style={{
                        marginRight: '1rem',
                        fontSize: '20px',
                        color: 'black',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <span style={{ fontWeight: '500', color: 'black' }}>
                        {option.name}
                      </span>
                      <span style={{ fontSize: '12px' }}>{option.tag}</span>
                    </div>
                  </Link>
                ))}
              </div>

              <div>
                <span
                  style={{
                    color: 'black',
                    marginBottom: '0.25rem',
                    marginTop: '1rem',
                    fontWeight: 'bold',
                  }}>
                  Book Listing
                </span>
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  }}>
                  {BookListingTools.map((option, index) => (
                    <Link
                      key={index}
                      to={option.link}
                      target="__blank"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '0.5rem',
                        padding: '0.375rem 0.25rem',
                        fontSize: '0.875rem',
                        color: 'gray',
                        textDecoration: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#c3abff';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.color = 'inherit';
                      }}
                      className="menu-item"
                      role="menuitem">
                      <option.Icon
                        style={{
                          marginRight: '1rem',
                          fontSize: '20px',
                          color: 'black',
                        }}
                      />
                      <span style={{ fontWeight: '500', color: 'black' }}>
                        {option.name}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
              <div>
                <span
                  style={{
                    color: 'black',
                    marginBottom: '0.25rem',
                    marginTop: '1rem',
                    fontWeight: 'bold',
                  }}>
                  General
                </span>
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  }}>
                  {GeneralTools.map((option, index) => (
                    <Link
                      key={index}
                      to={option.link}
                      target="__blank"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '0.5rem',
                        padding: '0.375rem 0.25rem',
                        fontSize: '0.875rem',
                        color: 'gray',
                        textDecoration: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#c3abff';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.color = 'inherit';
                      }}
                      role="menuitem">
                      <option.Icon
                        style={{
                          marginRight: '1rem',
                          fontSize: '20px',
                          color: 'black',
                        }}
                      />
                      <span style={{ fontWeight: '500', color: 'black' }}>
                        {option.name}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
              <div>
                <span
                  style={{
                    color: 'black',
                    marginBottom: '0.25rem',
                    marginTop: '1rem',
                    fontWeight: 'bold',
                  }}>
                  Learn
                </span>
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  }}>
                  {LearningTools.map((option, index) => (
                    <Link
                      key={index}
                      to={option.link}
                      target="__blank"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '0.5rem',
                        padding: '0.375rem 0.25rem',
                        fontSize: '0.875rem',
                        color: 'gray',
                        textDecoration: 'none',
                        backgroundColor: 'transparent',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#c3abff';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.color = 'inherit';
                      }}
                      role="menuitem">
                      <option.Icon
                        style={{
                          marginRight: '1rem',
                          fontSize: '20px',
                          color: 'black',
                        }}
                      />
                      <span style={{ fontWeight: '500', color: 'black' }}>
                        {option.name}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create;
