import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import SolutionsData, {
  TCategory,
  TResourcesData,
  Item,
} from './ResourcesData';
import { ChevronRight, ArrowDown } from './Solutions';

function ResourcesMenu() {
  const [selectedCategory, setSelectedCategory] = useState<TResourcesData>(
    SolutionsData[0],
  );

  const handleCategoryClick = (category: TResourcesData) => {
    setSelectedCategory(category);
  };
  return (
    <div style={{ height: 'fit-content', borderRadius: '16px', width: '78vw' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          maxHeight: '88vh',
          overflowY: 'auto',
        }}>
        <div
          style={{
            display: 'flex',
            borderRadius: '16px',
            padding: '12px',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '250px',
            }}>
            {SolutionsData.map((category: TResourcesData) => (
              <div
                key={category.Title as React.Key}
                style={{
                  display: 'flex',
                  borderRadius: '12px',
                  padding: '8px',
                  margin: '4px 0',
                  justifyContent: 'space-between',
                  color: 'black',
                  cursor: 'pointer',
                  backgroundColor:
                    selectedCategory.Title === category.Title
                      ? '#c3abff'
                      : 'white',
                }}
                onMouseEnter={() => handleCategoryClick(category)}>
                <span>{category.Title}</span>
                <ChevronRight />
              </div>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              paddingLeft: '16px',
              transition: 'height 0.3s',
            }}>
            {selectedCategory ? (
              // @ts-ignore
              <div key={selectedCategory.Title} className="category-content">
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(4, minmax(0, 1fr))`,
                    gap: '8px',
                  }}>
                  {selectedCategory.Categories.map((category: TCategory) => (
                    <div key={category.Heading} style={{ paddingLeft: '8px' }}>
                      {/* <span style={{ color: 'black', paddingLeft: '8px' }}>
                        {category.Heading}
                      </span> */}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          // marginTop: '12px',
                        }}>
                        {category.Items.map((item: Item) => (
                          <Link
                            to={item.link}
                            target={item.newTab ? '_blank' : '_self'}
                            key={item.link}
                            style={{ textDecoration: 'none' }}>
                            <div
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                borderRadius: '8px',
                                padding: '8px',
                                marginBottom: '8px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor =
                                  '#c3abff';
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = 'white';
                              }}>
                              <div
                                style={{
                                  fontWeight: 'bold',
                                  color: 'black',
                                }}>
                                {item.heading}
                              </div>
                              <div style={{ color: 'black' }}>{item.tag}</div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <span>Select a category to view details</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface SolutionsDropDownProps {
  show: boolean;
}

function SolutionsDropDown({ show }: SolutionsDropDownProps) {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button
        style={{
          background: 'transparent',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          cursor: 'pointer',
          color: 'black',
        }}>
        <span>Resources</span> <ArrowDown />
      </button>
      {show && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            backgroundColor: 'white',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            borderRadius: '16px',
            zIndex: 1,
          }}>
          <ResourcesMenu />
        </div>
      )}
    </div>
  );
}

export default SolutionsDropDown;
